<template>
  <button v-if="quitPipMode" @click="quitFromPiPMode">
    <icon-base :icon="IconQuit" class="cursor-pointer" viewBox="0 0 24 24" />
  </button>
  <button v-else v-tooltip="$t('COMMON.PIP.ACTIVATE')" @click="handlePiPMode">
    <icon-base :icon="IconPiP" class="cursor-pointer" viewBox="0 0 24 24" />
  </button>
</template>
<script setup lang="ts">
import IconBase from "@/components/ui/IconBase.vue";
import IconPiP from "@/components/icons/IconPiP.vue";
import IconQuit from "@/components/icons/IconQuit.vue";

import { PipType } from "@/enums/pip";
import { useEmitter } from "@/hooks/common";
import { useLocalStorageSetting } from "@/hooks/options";
import {
  DEFAULT_CALL_MODAL_OPTIONS,
  DEFAULT_DEAL_NOTES_OPTIONS
} from "@/helpers/constants/deals";
import { computed } from "vue";
import { NoteState } from "@/enums/notes";

const { type, quitPipMode } = defineProps<{
  type: PipType;
  quitPipMode?: boolean;
}>();

const emit = defineEmits<{
  click: [];
}>();

const emitter = useEmitter();

const callOptions = useLocalStorageSetting(
  "callModalOptions",
  DEFAULT_CALL_MODAL_OPTIONS
);

const noteOptions = useLocalStorageSetting(
  "dealNotesOptions",
  DEFAULT_DEAL_NOTES_OPTIONS
);

const activeOptions = computed(() =>
  type === PipType.Call ? callOptions.value : noteOptions.value
);

const handlePiPMode = () => {
  activeOptions.value.isPiPModeEnabled = true;
  if (type === PipType.Note) {
    noteOptions.value = {
      ...noteOptions.value,
      state: NoteState.PiP
    };
  }
  emitter.emit("OPEN_PIP_VIEW", type);
  emit("click");
};

const quitFromPiPMode = () => {
  activeOptions.value.isPiPModeEnabled = false;
  if (type === PipType.Note) {
    noteOptions.value = {
      ...noteOptions.value,
      state: NoteState.Draggable
    };
  }
  emitter.emit("CLOSE_PIP_VIEW");
  emit("click");
};
</script>
